/*输入邮箱获得折扣*/
.sendEmail_getCode .getCode_main_warp {
  width: 100%;
  padding: 30px 0 0;
}
.sendEmail_getCode .getCode_main_warp .email_input {
  height: 40px;
  width: 310px;
  max-width: 100%;
}
.sendEmail_getCode .getCode_main_warp .btn_getCode,
.p-l-as-content .btn_top_getCode {
  height: 40px;
  width: 160px;
  display: inline-block;
  background-color: #323232;
  color: white;
  font-size: 14px;
  line-height: 40px;
  margin: 10px 0;
  border: none;
}
.sendEmail_getCode .getCode_main_warp .btn_getCode:hover,
.p-l-as-content .btn_top_getCode:hover {
  background-color: white;
  border: 1px solid #333333;
  color: black;
  text-decoration: none;
  font-weight: 600;
}
.getCode_main_warp p {
  margin: 16px 0;
}
.form-horizontal2 label {
  display: block;
}
.UPAlert .btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.UPAlert .btn-info {
  background-color: #26ABFF;
  border-color: #26ABFF;
  color: #fff;
  padding: 4px 15px;
  font-weight: 400;
}
.UPAlert * {
  max-width: 100%;
  box-sizing: border-box;
}
.help_contact_login_href {
  color: #26ABFF;
}
@media screen and (max-width: 900px) {
  .pro-color-container iframe {
    max-width: 100%;
    width: 560px;
  }
}
.footer_bar {
  width: 100%;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
  text-align: right;
  padding: 10px;
  border-top: 1px solid #ddd;
  display: block;
}
.pro-color-container {
  -webkit-overflow-scrolling: touch;
  padding: 20px 0 10px;
}
.help_faq_contact_footer .help_return_td_sub {
  display: inline-block;
  background-color: #323232;
  color: #fff;
  padding: 6px 40px;
  margin-bottom: 10px;
  border: 1px solid transparent;
}
.help_faq_contact_footer .help_return_td_sub:hover {
  color: #888;
  background-color: #fff;
  text-decoration: none;
  border: 1px solid #323232;
}
.sendEmail_getCode .getCode_main_warp .input-group .btn_getCode {
  margin: 0;
  height: 40px;
  background: #333;
  border: 1px solid #333;
  line-height: 27px;
  color: #fff;
  width: 100%;
}
.contact_us_form input,
.contact_us_form textarea {
  outline: 0;
  padding: 0 5px;
  background-color: transparent;
  border: 1px solid #999;
  width: 270px;
}
.contact_us_form .help_captcha {
  width: 80px;
}
