@import "../../public/static/icons/iconfont.css";
/* 在 src/index.css 或 src/App.css 中添加 */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
/* @import "../../public/static/less/font.css"; */
@import "../../public/static/less/bootstrap.min.css";
@import "../../public/static/less/adSevers.css";
html {
    font-size: 100px;
    box-sizing: border-box;
    font-size: 13.3333vw !important;
    scroll-behavior: smooth;
}
* {
    text-transform: none !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    position: relative;
    overflow-x: hidden;
}
*,
:after,
:before {
    /* 解决图标被设置cursor-pointer时出现的默认浅蓝色背景问题 */
    -webkit-tap-highlight-color: transparent;
}

body {
    --vh: 1vh;
    font-family: var(--font-montserrat);
    font-size: 0.28rem;
    line-height: 1.5;
    letter-spacing: normal;
}

.hidden {
    display: none !important;
}

.visibility-hidden {
    visibility: hidden;
}

/* link-default */
a {
    text-decoration: none;
    color: #2ea7e0;
}
a:hover {
    text-decoration: underline;
}

.default_layout {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mobile_item_container {
    padding: 0.4rem 0.2rem;
    background-color: #fff;
    margin-bottom: 0.2rem;
}

.setOverflowAutoImportantly {
    overflow: auto !important;
}
.setOverflowHiddenImportantly {
    overflow: hidden !important;
}

/* 解决在有弹窗的基础上wheel仍然可以滚动导致header的zindex可能为0的问题 */
body:not(.setOverflowHiddenImportantly) .z-index-zero {
    z-index: 0 !important;
}

/* height, 伪类 */
.height_bar {
    height: 0.2rem;
    width: 100%;
    background-color: #f8f8f8;
}

.text-center {
    text-align: center;
}
/* 处理heightBar组件，延长其宽度到100vw */
.height-bar {
    width: calc(100% + 0.4rem);
    margin: 0 -0.2rem;
}

h2 {
    line-height: 1.1;
}

iframe {
    max-width: 100%;
}
/* 覆盖bootstrap的p标签默认样式 */
p {
    margin: 0;
}
/* 字号列表 */
/* .font-title {
    font-size: 0.36rem;
    line-height: 1.5;
    font-family: Montserrat-Medium;
    color: #121a24;
} */

/*  快牛的层级设置 */
#quick-chat-iframe {
    z-index: 99 !important;
}

/* safari 15-16.3bug修复 */
@supports (font: -apple-system-body) and (-webkit-appearance: none) {
    img[loading="lazy"] {
        clip-path: inset(0.6px);
    }
}
